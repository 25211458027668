/* list */

.result_items_list {
  justify-content: center;
  flex-wrap: wrap;

  padding: 0 6px;
  margin: 0 auto;

  box-sizing: border-box;
}

.list_item {
  display: inline-block;

  text-align: center;
  max-width: 50%;
  width: 50%;
  height: 245px;

  box-sizing: border-box;
  padding: 5px 8px;

  margin-bottom: 40px;
}

.list_item .item_image {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 150px;
  /* 
  background-color: blanchedalmond; */

  margin-bottom: 10px;
}

.list_item .item_image img {
  max-width: 100%;
  max-height: 100%;
}

.list_item .item_brand_info {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 6px;
}

.list_item .item_brand_info .manufacturer {
  width: fit-content;
  height: 17px;

  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: -0.22px;
  text-align: center;

  color: #ffffff;
  background-color: #a5a5a5;

  padding: 0 2px;
}

.list_item .item_brand_info .brand_line {
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: -0.22px;
  color: #a5a5a5;

  margin-left: 4px;
}

.list_item .item_name {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: -0.75px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);

  word-wrap: break-word;
  word-break: keep-all;

  margin-bottom: 6px;
}

.list_item .item_price {
  height: 20px;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.3px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);

  margin-bottom: 3px;
}

.list_item .item_rating {
  width: fit-content;
  height: 12px;

  margin: auto;
}

.list_item .item_rating img {
  width: 12px;
  height: 12px;

  float: left;

  margin-right: 1px;
}
