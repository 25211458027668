.recent_keywords {
  width: 100%;
  height: fit-content;

  box-sizing: border-box;
  padding: 0 22px;

  margin-top: 10px;
}

.recent_keywords .title {
  width: 100%;
  height: 22px;

  position: relative;

  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 16px;
}

.recent_keywords .title .title_txt {
  position: absolute;
  left: 0;

  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: -1.04px;
  text-align: left;
  color: rgba(164, 164, 164, 0.8);
}

.recent_keywords .title .delete_btn {
  position: absolute;
  right: 0;

  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.88px;
  text-align: left;
  color: rgba(164, 164, 164, 0.8);
}

.recent_keywords .recent_keyword_list {
}

.recent_keywords .recent_keyword {
  position: relative;

  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 21px;

  margin-bottom: 19px;
}

.recent_keywords .recent_keyword a {
  width: 100%;
}

.recent_keywords .recent_keyword .search_btn {
  width: 12px;
  height: 12px;

  margin-right: 10px;
}

.recent_keywords .recent_keyword .search_btn img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: text-top;
}

.recent_keywords .recent_keyword .delete_btn {
  float: right;

  width: 21px;
  height: 21px;

  margin-left: 7px;
}

.recent_keywords .recent_keyword .delete_btn img {
  width: 7px;
  height: 7px;
  vertical-align: text-top;
  padding: 7px;
}

.recent_keywords .recent_keyword .keyword_text {
  width: 100%;
  height: 21px;

  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: -1.2px;
  text-align: left;

  color: rgba(46, 46, 46, 0.8);
}
