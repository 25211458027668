.dm {
  width: 100%;
  height: 40px;
}

.wrapper {
  padding: 0 25px;
}

.page_title {
  height: 41px;

  margin-bottom: 32px;
}

.page_title .title_text {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 41px;
  letter-spacing: -2.24px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
}

.page_title .close_btn {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  float: right;

  width: 41px;
  height: 41px;
}

.page_title .close_btn img {
  width: 16px;
  height: 16px;

  margin: auto;
}

.product_info {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;

  width: 100%;
  height: 117px;
  border-radius: 16px;
  // box-shadow: 0 10px 40px 0 rgba(220, 220, 220, 0.23);
  background-color: #f6f6f6;

  box-sizing: border-box;
  padding: 13.5px 10px 13.5px 10px;
  margin-bottom: 50px;
}

.product_info_image {
  width: 114px;
  height: 90px;
  text-align: center;
}

.product_info_image img {
  max-width: 100%;
  max-height: 100%;
}

.product_info_text {
  flex: 1;
  height: 90px;

  box-sizing: border-box;

  padding-top: 3px;
  padding-left: 7px;
  padding-right: 8px;
}

.product_info_text .product_name {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: keep-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  max-height: 45px;

  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.8px;
  text-align: left;
  color: #181818;
  margin-bottom: 2px;
}

.product_info_text .brand_name {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  max-height: 20px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.7px;
  text-align: left;
  color: #999999;

  margin-bottom: 2px;
}

.product_info_text .rating img {
  width: 12px;
  height: 12px;

  vertical-align: middle;
  margin-right: 3px;
}

.product_info_text .rating span {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.7px;
  text-align: left;
  color: #000000;
}

.stars {
  position: relative;

  height: 42px;

  text-align: center;

  margin-bottom: 46px;
}

.stars img {
  width: 42px;
  height: 42px;
  margin: 0 3.5px;
}

.stars .hidden_slider {
  position: absolute;

  width: 100%;
  height: 42px;

  margin: 0 auto;
}

.stars .hidden_slider input {
  -webkit-appearance: none;
  width: 245px;
  height: 42px;
}

.stars .hidden_slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 42px;
  width: 36px;
  background: #555;
  cursor: pointer;
}

.stars .hidden_slider input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 42px;
  cursor: pointer;
  background: #ccc;
}

.input_wrapper {
  width: 100%;
  height: 231px;
  border-radius: 15px;
  background-color: #f9f9f9;
  box-sizing: border-box;

  margin-bottom: 62px;
}

.input_wrapper .preview_text {
  width: 100%;
  height: 100%;

  border: 0;
  background-color: transparent;

  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -1.13px;
  text-align: left;
  color: #5d5d5d;

  box-sizing: border-box;
  padding: 13px 18px;
}

.preview_text::placeholder {
  color: #c8c8c8;
}

.btn_section {
  width: 100%;

  text-align: center;
}

.btn_section .btn {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  width: 190px;
  height: 46px;
  background-color: #fcb03f;

  margin: 0 auto;
}

.btn_section .btn span {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.47;
  letter-spacing: -0.75px;
  text-align: right;
  color: #ffffff;
}
