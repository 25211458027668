.logoHeader {
  width: 100%;
  height: 44px;
  padding: 14.5px 26px;
  margin-bottom: 27px;

  .logoImage {
    position: relative;

    width: auto;
    height: 20px;

    img {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.contents {
  padding: 0 16px;
  text-align: center;

  max-width: 500px;

  margin: auto;

  background-color: #f8f8f8;

  padding-bottom: 28px;
}

.mainBanner {
  margin-bottom: 28px;
  background-color: transparent;

  img {
    object-fit: contain;
    background-color: transparent;

    width: 100%;
    max-width: 500px;

    border-radius: 15px;
    box-shadow: 0 3px 15px 0 rgba(128, 128, 128, 0.3);
  }
}

.miniBanner {
  margin-bottom: 28px;

  img {
    width: 100%;
    max-width: 500px;

    border-radius: 15px;
    box-shadow: 0 3px 15px 0 rgba(128, 128, 128, 0.3);
  }
}

.theme {
  background-color: transparent;

  .themeElement {
    width: 100%;
    max-width: 500px;

    background-color: white;

    border-radius: 15px;
    box-shadow: 0 3px 30px 0 rgba(167, 167, 167, 0.13);

    margin: auto;

    .themeImage {
      position: relative;

      width: 100%;

      img {
        max-width: 100%;
        border-radius: 15px;

        box-shadow: 0 3px 15px 0 rgba(128, 128, 128, 0.12);
      }
    }

    .themeInfo {
      padding: 19px 19px 31px 19px;

      .themeSectionText {
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: -0.45px;
        text-align: left;
        color: #6b6b6b;

        margin-bottom: 5px;
      }

      .themeTitleText {
        font-size: 25px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.88;
        letter-spacing: -0.75px;
        text-align: left;
        color: #333333;

        margin-bottom: 12px;
      }

      .themeContentText {
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: -0.42px;
        text-align: left;
        color: #515151;
      }
    }
  }
}

/* */

.contents_post {
  text-align: center;

  background-color: #f8f8f8;

  padding: 0 16px;
  padding-top: 35px;
  padding-bottom: 28px;

  max-width: 500px;

  margin: auto;

  .section_title {
    width: 100%;
    height: 30px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 24px;

    .post_text {
      font-size: 23px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.96;
      letter-spacing: -0.69px;
      text-align: center;
      color: #333333;
    }

    .more {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.83;
      letter-spacing: -0.36px;
      text-align: center;
      color: #828282;
    }
  }
}

.postElement {
  width: 100%;

  background-color: white;

  border-radius: 15px;
  box-shadow: 0 3px 30px 0 rgba(167, 167, 167, 0.13);
  border: 0.5px solid rgba(128, 128, 128, 0.2);
  margin: auto;
  margin-bottom: 33px;

  .postImage {
    position: relative;

    width: 100%;

    img {
      max-width: 100%;
      border-radius: 15px 15px 0 0;
    }
  }

  .postInfo {
    padding: 20px 17px 24px 17px;

    .postTitleText {
      font-size: 20px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: -0.6px;
      text-align: left;
      color: #333333;

      margin-bottom: 7px;
    }

    .postContentText {
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: -0.42px;
      text-align: left;
      color: #6a6a6a;
    }
  }
}
