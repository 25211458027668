@import url("https://fonts.googleapis.com/css?family=Noto+Sans+KR:300,400,500,700,900&display=swap&subset=korean");

.wrapper {
}

.buttons {
  height: 60px;
}

.product_basic_info {
  text-align: center;
  padding: 0 15px;
}

.product_image {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  height: 221px;
  /* background-color: lightblue; */

  margin-bottom: 25px;
}

.product_image > img {
  max-width: 100%;
  max-height: 100%;
}

.product_info {
}

/* 상품 기본 정보 */
.brand_and_tags {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;

  height: 22px;

  margin-bottom: 4px;
}

.brand {
  display: inline;
  height: 22px;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: -0.24px;
  text-align: left;
  color: #fcb03f;

  margin-right: 6px;
}

.tags {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
}

.tag {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: 18px;
  border-radius: 10px;
  background-color: #fcb03f;

  box-sizing: border-box;
  padding: 1px 9px;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-right: 4px;
}

.tag > span {
  height: 12px;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 12px;
  letter-spacing: -0.16px;
  text-align: center;
  color: #ffffff;
}

.product_name {
  font-size: 19px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.8px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);

  height: 24px;

  margin-bottom: 10px;
}

.product_price_and_discount {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: flex-end;

  height: 37px;

  margin-bottom: 15px;
}

.product_price_and_discount > .price {
  height: 37px;
  font-size: 28px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.3px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);

  margin-right: 2px;
}

.product_price_and_discount > .won {
  height: 24px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.32px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
  vertical-align: bottom;

  margin-right: 6px;
  margin-bottom: 4px;
}

.product_price_and_discount > .discount {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: 20px;
  border-radius: 10px;
  background-color: #f26838;

  box-sizing: border-box;
  padding: 1px 10px;

  margin-right: 6px;
  margin-bottom: 4px;
}

.product_price_and_discount > .discount > span {
  height: 12px;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 12px;
  letter-spacing: -0.16px;
  text-align: center;
  color: #ffffff;
}

.separator_line {
  position: absolute;
  left: 0;

  width: 100%;
  height: 1px;
  background-color: #f5f5f5;
}

.product_rating {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;

  height: 53px;
  /* background-color: aquamarine; */
}

.product_rating .stars {
  display: inline-block;
  height: 15px;

  margin-right: 4px;
}

.product_rating .stars img {
  width: 15px;
  height: 15px;
  object-fit: contain;
  float: left;

  margin-right: 2px;
}

.product_rating .rating {
  height: 16px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: -0.28px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);

  margin-right: 4px;
}

.product_rating .number_of_ratings {
  height: 14px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 14px;
  letter-spacing: -0.24px;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
}

.product_rating .number_of_ratings::before {
  height: 12px;
  content: "(";
  font-size: 11px;
  line-height: 12px;
}

.product_rating .number_of_ratings::after {
  height: 12px;
  content: ")";
  font-size: 11px;
  line-height: 12px;
}

.product_rating > .push_margin {
  margin-left: auto;
}

.product_rating > .taste {
  margin-top: 3px;
  margin-right: 22px;
}

.product_rating > .taste > img {
  width: 22px;
  height: 22px;

  margin-bottom: 2px;
}

.product_rating > .taste > span {
  display: block;

  height: 12px;
  font-size: 9px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.08px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
}

.product_rating > .like {
  margin-top: 3px;
  margin-right: 3px;
}

.product_rating > .like > img {
  width: 20px;
  height: 18px;

  margin-bottom: 4px;
}

.product_rating > .like > span {
  display: block;

  height: 12px;
  font-size: 9px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.08px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
}

.section_separator_line {
  position: absolute;
  left: 0;

  width: 100%;
  height: 6px;
  background-color: #f5f5f5;

  margin-top: 1px;
}

.section_separator_line_upper {
  position: absolute;
  left: 0;

  width: 100%;
  height: 1px;
  background-color: #ebebeb;
}

/* user review 시작 */
.user_comment {
  margin-top: 7px;
}

.user_comment_title {
  height: 53px;
  padding: 0 15px;
}

.new_comment_btn_wrapper {
  height: 84px;
  box-sizing: border-box;

  padding-top: 12px;
  padding-bottom: 32px;
}

.new_comment_btn {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  width: 200px;
  height: 40px;
  background-color: #fcb03f;

  margin: auto;
}

.new_comment_btn > div {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.7px;
  text-align: right;
  color: #ffffff;
  margin: auto;
}

.user_comment_title > .title {
  height: 53px;
  float: left;

  opacity: 0.9;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 53px;
  letter-spacing: -1.7px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
}

.user_comment_title > .more {
  height: 53px;
  float: right;

  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 53px;
  letter-spacing: -0.24px;
  text-align: right;
  color: #fcb03f;
}

.comment {
  position: relative;

  padding: 11px 18px 11px 18px;
}

.my_comment {
  background-color: aliceblue;
}

.comment .user_name {
  float: left;

  height: 19px;
  font-size: 13px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: -0.26px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
}

.comment .date {
  float: right;
  right: 0;
  position: absolute;
  height: 17px;
  opacity: 0.5;
  font-size: 11px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: -0.22px;
  text-align: right;
  color: rgba(0, 0, 0, 0.8);
}

.comment > .comment_basic_info {
  display: flex;
  align-items: center;

  margin-bottom: 5px;
  position: relative;
}

.comment > .comment_basic_info img {
  float: left;
  width: 13px;
  height: 13px;

  margin-right: 3px;
}

.comment > .comment_basic_info::after {
  content: "";
  display: table;
  clear: both;
}

.comment > .user_stars {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;

  margin-bottom: 12px;
}

.comment > .user_stars > img {
  width: 11px;
  height: 11px;
  object-fit: contain;

  margin-right: 3px;
}

.comment > .comment_content {
  display: inline-block;
  height: 29px;
  font-size: 13px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: -0.26px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  margin-bottom: 8px;
}

.comment > .comment_like {
  width: fit-content;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: flex-end;
}

.comment > .comment_like > .thumbs {
  margin-right: 4px;
}

.comment > .comment_like > .thumbs > img {
  width: 12px;
  height: 13.1px;
}

.comment > .comment_like > .number_of_likes {
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.2px;
  color: rgba(0, 0, 0, 0.8);
}

.creator_contents {
}
