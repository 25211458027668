$size_of_star: 24px;

body {
  letter-spacing: -0.5px;
}

.upper-bg {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 30%;

  background-color: #ffe1ba;

  .title-text {
    position: relative;
    top: 30px;
    left: 32px;

    width: fit-content;

    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: #000000;

    span {
      font-weight: 600;
    }
  }

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 35px;
    right: 40px;

    width: 100px;
    height: 100px;

    border-radius: 50px;
    border: 2px solid #000000;
    background-color: #ffe41d;
    // background: linear-gradient(90deg, #ffe41d 50%, transparent 50%), linear-gradient(180deg, black 50%, red 50%);
    background: linear-gradient(270deg, red 50%, transparent 50%), linear-gradient(54deg, red 50%, black 50%);

    .inner-circle {
      display: flex;
      align-items: center;
      justify-self: center;

      text-align: center;

      z-index: 2;
      width: 85%;
      height: 85%;
      border-radius: 50%;

      border: 2px solid black;
      background-color: #ffe1ba;
    }

    .selected-count {
      width: 100%;

      z-index: 3;
      font-size: 34px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    .blink_me {
      animation: blinker 2s linear infinite;
    }

    @keyframes blinker {
      50% {
        // opacity: 0.3;
        background-color: rgb(180, 112, 9);
      }
    }

    .next-btn {
      z-index: 4;

      position: absolute;
      bottom: 0;
      right: -26px;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 52px;
      height: 52px;
      box-shadow: 0 2px 5px 0 rgba(180, 180, 180, 0.34);
      border-radius: 26px;
      // background-color: red;
      background-color: #333333;

      img {
        width: 48%;
        height: 48%;
      }
    }
  }
}

.lower-bg {
  background-color: #eeeeee;
  position: relative;
  z-index: 2;
  top: -30px;

  width: 100%;
  height: calc(70% + 30px);

  background-color: white;
  border-radius: 30px 30px 0 0;

  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
}

.list-wrapper {
  position: absolute;
  top: 0px;

  width: 100%;
  height: calc(100%);
  box-sizing: border-box;
  padding: 0 30px;
  padding-top: 30px;

  overflow-y: auto;

  .list-item {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    max-width: 350px;
    width: 100%;
    height: 140px;

    background-color: white;
    border: solid 2px #000000;
    border-radius: 20px;

    padding: 10px 12px;
    padding-left: 12px;
    margin: 0 auto;
    margin-bottom: 25px;

    .product-image {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 35%;
      height: 100%;

      // background-color: lightblue;
      margin-right: 10px;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;

        margin: auto;
      }
    }

    .product-info {
      flex: 1;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      height: 100%;

      .brand-name {
        font-size: 16px;
        font-weight: 400;

        margin-bottom: 6px;
        margin-left: 5px;
      }

      .product-name {
        font-size: 20px;
        font-weight: 600;

        max-height: 46px;
        overflow: hidden;
        text-overflow: ellipsis;

        margin-bottom: 10px;
        margin-left: 5px;
      }

      .rating-info {
        display: flex;
        align-items: center;
        justify-content: center;

        .rating-stars {
          position: relative;
          height: $size_of_star;

          outline: 0;

          margin-right: 10px;
        }

        .rating-text {
          text-align: center;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }

  .list-item-selected {
    border: solid 4px #000000;
    padding: 8px 10px;
  }

  .list-item-selected-checked {
    display: flex;
    align-items: center;
    justify-self: center;

    position: absolute;
    top: -2px;
    right: -2px;

    background-color: #000000;

    width: 36px;
    height: 36px;

    border-radius: 0px 20px 0px 20px;

    .checked-img {
      width: 55%;
      height: 55%;

      display: block;
      margin: auto;
    }
  }
}

.f-slider {
  position: absolute;
  width: 100%;
  height: 80%;
  bottom: 0;
  z-index: 3;

  // background-color: green;
}

.rating-stars img {
  z-index: 5;

  width: $size_of_star;
  height: $size_of_star;

  margin-right: 2px;
}

@media only screen and (max-device-height: 660px) {
  $size_of_star: 20px;

  .list-wrapper {
    top: 0px;
    height: calc(100%);
  }

  .title-text {
    font-size: 24px !important;
  }

  .product-image {
    width: 30%;
  }

  .product-info {
    .brand-name {
      font-size: 14px !important;
    }

    .product-name {
      font-size: 17px !important;
    }
  }

  .rating-stars {
    margin-right: 5px !important;
  }
  .rating-stars img {
    z-index: 5;

    width: $size_of_star;
    height: $size_of_star;
    margin-right: 2px;
  }
  .rating-text {
    font-size: 16px !important;
  }
}
