@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .upper {
    width: 100%;
    background-position: 49.999% 0 !important;
  }
}

.separator .sep_top {
  width: 100%;
  height: 1px;

  background-color: #ebebeb;
}

.separator .sep_btm {
  width: 100%;
  height: 5px;

  background-color: #f5f5f5;
}

.upper {
  width: 100%;
  height: 316px;

  // background-image: url(/images/main_bg_cr.png);
  background-repeat: no-repeat;
  background-size: cover;

  margin: 0;
}

.upper .device_margin {
  width: 100%;
  height: 66px;
}

.upper .logo {
  height: 152px;
  text-align: center;

  margin-bottom: 30px;
}

.upper .logo img {
  width: 183px;
  height: 152px;
}

.upper .search_bar {
  text-align: center;
}

.upper .search_bar .search_input {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;

  width: 343px;
  height: 52px;
  border-radius: 26px;
  box-shadow: 0 0 6px 0 rgba(255, 209, 141, 0.65);
  border: solid 1px #ffe7c3;
  background-color: #ffffff;

  margin: 0 auto;
}

.upper .search_bar .search_input .search_btn {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  border-radius: 20px;
  background-color: rgba(252, 176, 63, 0.1);

  margin-left: 6px;
}

.upper .search_bar .search_input .search_btn img {
  width: 16px;
  height: 16px;
}

.upper .search_bar .search_input .space {
  flex: 1;
}

.upper .search_bar .search_input .image_search_btn {
  width: 22.7px;
  height: 20.3px;

  margin-right: 20px;
}

.upper .search_bar .search_input .image_search_btn img {
  max-width: 100%;
  max-height: 100%;
}

.upper .search_bar .search_input .barcode_search_btn {
  width: 20px;
  height: 18px;

  margin-right: 22px;
}

.upper .search_bar .search_input .barcode_search_btn img {
  max-width: 100%;
  max-height: 100%;
}

/* contents */

.contents {
}

.contents .ranking {
  margin-bottom: 20px;
}

.contents .ranking .section_title {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;

  height: 60px;
  padding: 0 20px;
  margin: 8px 0px;
}

.section_title_text {
  height: 28px;

  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  line-height: 26px;
  letter-spacing: -1.44px;
  color: rgba(0, 0, 0, 0.8);
}

.contents .ranking .section_title .space {
  flex: 1;
}

.contents .ranking .section_title .criteria {
}

.criteria_btn {
  float: left;

  text-align: center;
  width: 44px;
  height: 28px;
  border-radius: 4px;

  margin-left: 4px;
}

.criteria_btn span {
  height: 28px;
  font-size: 12px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.24px;
  text-align: center;

  margin: auto;
}

.selected {
  background-color: #fcb03f;
  color: white;
}

.ranking_page_track {
  overflow-y: hidden;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;

  margin-left: 16px;
}

.ranking_page {
  position: relative;
  display: inline-block;
  width: calc(100vw - 16px);
  height: 100%;
}

.ranking_page:last-child {
  margin-right: calc(100vw - 346px);
}

.ranking_each_item {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;

  margin-bottom: 19px;
}

.ranking_each_item .ranking_number {
  width: 25px;
  text-align: center;

  margin-right: 8px;
  margin-bottom: 12px;
}

.ranking_each_item .ranking_number span {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: -0.56px;
  text-align: left;
  color: #292929;

  height: 60px;
}

.ranking_each_item .ranking_item_image {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 50px;
  background-color: transparent;
}

.ranking_each_item .ranking_item_image img {
  max-width: 100%;
  max-height: 100%;
}

.ranking_each_item .ranking_item_info {
}

.ranking_each_item .ranking_item_info .ranking_item_name {
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: -1.2px;
  text-align: left;
  color: #292929;
}

.ranking_each_item .ranking_item_info .ranking_item_brandname {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: -0.96px;
  text-align: left;
  color: #7e7e7e;
}

/* choice 시작 */

.choice {
}

.choice .section_title {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;

  height: 60px;
  padding: 0 15px;
  margin: 8px 0px;
}

.choice .choice_slider {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
  flex-wrap: nowrap;

  width: 100%;
  height: 188px;
  overflow-x: auto;

  margin-bottom: 21px;
}

.choice .choice_content {
  flex: 0 0 auto;
  width: 345px;
  height: 188px;

  background-color: lightpink;

  margin-right: 20px;
}

/* review 시작 */

.review .section_title {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;

  height: 60px;
  padding: 0 15px;
  margin: 8px 0px;
}

.review .review_slider {
  width: 100%;
  height: 188px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  box-sizing: border-box;

  padding-left: 16px;
  margin-bottom: 21px;
}

.review .review_content {
  display: inline-block;
  width: 104px;
  height: 104px;

  background-color: lightblue;

  margin-right: 11px;
}
