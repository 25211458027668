@import "common";

.main_search_bar {
  max-width: 500px;
  width: 100%;

  position: fixed;
  z-index: 8888;
  box-sizing: border-box;
  background-color: white;

  @include center_align_with_flex;

  padding: 10px 22px 10px 22px;

  .text_field {
    @include center_align_with_flex;
    justify-content: flex-start;

    flex: 1;
    top: 0;

    width: 100%;
    height: 33px;
    border-radius: 7px;
    background-color: #f7f7f7;

    padding-left: 33px;
    margin-right: 11px;

    span {
      font-size: 13px;
      font-weight: 400;
      color: #cccccc;
      letter-spacing: -1px;

      margin-top: -2px;
    }
  }

  .search_icon {
    position: absolute;
    left: 33px;
    top: 20px;
    width: 13px;
    height: 13px;

    img {
      position: absolute;
      top: 0;
      left: 0;

      filter: brightness(1.5);
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .barcode_icon {
    width: 33px;
    height: 33px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.section_group {
  width: 100%;

  box-sizing: border-box;
  padding: 0 25px;

  margin-bottom: 20px;
}

.section_title {
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: -1.05px;
  text-align: left;

  padding: 0 10px;
  margin-bottom: 15px;
}

.section_icons {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: space-between;

  flex-wrap: wrap;
}

.section_icons .icon {
  flex: 0 0 75px;
  max-width: 100px;

  text-align: center;

  margin-bottom: 25px;
}

.section_icons .icon_img {
  @include center_align_with_flex;

  height: 60px;
}
.section_icons .icon_img img {
  max-width: 60px;
  max-height: 60px;

  object-fit: contain;
}

.section_icons .icon_text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.7px;
  color: rgba(29, 29, 29, 0.8);
}
