/* user review 시작 */
.user_comment {
  margin-top: 7px;
}

.user_comment_title {
  height: 53px;
  padding: 0 15px;
}

.new_comment_btn_wrapper {
  height: 84px;
  box-sizing: border-box;

  padding-top: 12px;
  padding-bottom: 32px;
}

.new_comment_btn {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  width: 200px;
  height: 40px;
  background-color: #fcb03f;

  margin: auto;
}

.new_comment_btn > div {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.7px;
  text-align: right;
  color: #ffffff;
  margin: auto;
}

.user_comment_title > .title {
  height: 53px;
  float: left;

  opacity: 0.9;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 53px;
  letter-spacing: -1.7px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
}

.user_comment_title > .more {
  height: 53px;
  float: right;

  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 53px;
  letter-spacing: -0.24px;
  text-align: right;
  color: #fcb03f;
}

.comment {
  position: relative;

  padding: 11px 18px 11px 18px;
}

.my_comment {
  background-color: aliceblue;
}

.comment .user_name {
  float: left;

  height: 19px;
  font-size: 13px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: -0.26px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
}

.comment .date {
  float: right;
  right: 0;
  position: absolute;
  height: 17px;
  opacity: 0.5;
  font-size: 11px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: -0.22px;
  text-align: right;
  color: rgba(0, 0, 0, 0.8);
}

.comment > .comment_basic_info {
  display: flex;
  align-items: center;

  margin-bottom: 5px;
  position: relative;
}

.comment > .comment_basic_info img {
  float: left;
  width: 13px;
  height: 13px;

  margin-right: 3px;
}

.comment > .comment_basic_info::after {
  content: "";
  display: table;
  clear: both;
}

.comment > .user_stars {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;

  margin-bottom: 12px;
}

.comment > .user_stars > img {
  width: 11px;
  height: 11px;
  object-fit: contain;

  margin-right: 3px;
}

.comment > .comment_content {
  display: inline-block;
  height: 29px;
  font-size: 13px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: -0.26px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  margin-bottom: 8px;
}

.comment > .comment_like {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: flex-end;
}

.comment > .comment_like > .thumbs {
  margin-right: 4px;
}

.comment > .comment_like > .thumbs > img {
  width: 12px;
  height: 13.1px;
}

.comment > .comment_like > .number_of_likes {
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.2px;
  color: rgba(0, 0, 0, 0.8);
}
