.test-gnb {
  position: relative;
  flex: initial;
  width: 100%;
  height: 66px;

  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: lightcoral;
  max-width: 500px;
  left: 0;
  bottom: 0;
  border-top: 1px solid #e1e1e1;
  padding: 9px 28px;

  .gnb-btn {
    /* clickable area */
    width: 33.3%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    .gnb-btn-img {
      /* actual image area */
      width: 22px;
      height: 22px;
      background-color: #ffeed9;

      border: 11px solid #ffd196;
      box-sizing: content-box;
    }
  }
}
