$base-color: #f8833e;
$orange: #f8833e;

// light: 300 | regular: 400 | medium: 500 | bold: 700
@mixin section_wrapper {
  min-height: calc(100vh - 122px + 500px);

  margin-top: 0px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

@mixin center_align_with_flex {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;

  align-items: center;
  justify-content: center;
}

.back_btn {
  position: absolute;

  left: 0;
}

.back_btn img {
  width: 9.3px;
}
