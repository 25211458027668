.title_bar {
  position: fixed;
  top: 0;
  z-index: 999;

  background-color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 53px;

  box-sizing: border-box;
  padding: 18px 40px 18px 40px;
}

.title_bar .back_btn {
  position: absolute;

  width: 9.3px;
  height: 15.2px;

  left: 15px;

  margin-top: 1px;
  padding: 5px;
}

.title_bar .close_btn {
  position: absolute;

  width: 14px;
  height: 14px;

  right: 15px;

  margin-top: 1px;

  padding: 5px;
}

.title_bar .title_bar_text {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.85px;
  text-align: left;
  color: #000000;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.contents {
  width: 100%;
  height: 100vh;

  margin-top: 53px;
}

.contents iframe {
  width: 100%;
  height: 100%;
}
