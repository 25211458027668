body {
  letter-spacing: -0.5px;
  box-sizing: border-box;
}

.upper {
  position: relative;
  z-index: 0;

  height: 50%;
  background-color: #ff9a22;

  .text {
    position: absolute;
    top: 32px;
    left: 32px;

    font-size: 40px;
    font-weight: 300;

    .bold-text {
      font-weight: 600;
    }
  }

  .yellow-img {
    z-index: 1;

    position: absolute;
    left: 20px;
    bottom: -30px;
  }

  .red-img {
    z-index: 2;

    position: absolute;
    right: 20px;
    bottom: -60px;
  }

  .orange-img {
    z-index: 3;

    position: absolute;
    right: 20px;
    bottom: 30%;
  }
}

.bottom {
  position: relative;
  z-index: 5;

  height: calc(50% + 60px);
  border-radius: 30px;
  top: -60px;

  background-color: white;

  padding-left: 12.5%;
  padding-right: 12.5%;
  padding-top: 8%;

  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  .title {
    font-size: 28px;
    font-weight: bold;

    margin-bottom: 30px;
  }

  .form {
    select {
      width: 100%; /* 원하는 너비설정 */
      height: 100%;
      padding: 0.8em 0.5em; /* 여백으로 높이 설정 */
      font-family: inherit; /* 폰트 상속 */
      background: url("bottom_arrow.svg") no-repeat 100% 50%; /* 네이티브 화살표를 커스텀 화살표로 대체 */
      border: 0;
      border-radius: 0px; /* iOS 둥근모서리 제거 */
      -webkit-appearance: none; /* 네이티브 외형 감추기 */
      -moz-appearance: none;
      appearance: none;
      outline: 0;
    }

    .field-title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 7px;
    }

    .nickname {
      .nickname-field {
        width: 100%;
        height: 50px;
        border-radius: 25px;
        border: solid 2px #000000;

        input {
          width: 100%;

          background-color: transparent;

          border: 0;
          font-size: 20px;
          line-height: 44px;

          padding-left: 20px;
          padding-right: 20px;
        }
      }

      margin-bottom: 20px;
    }

    .etc_info {
      display: flex;

      .agespan {
        width: 50%;

        margin-right: 10px;

        .agespan-field {
          width: 100%;
          height: 50px;
          border-radius: 25px;
          border: solid 2px #000000;

          padding: 2px 10px;
        }
      }

      .gender {
        width: 50%;

        margin-left: 10px;

        .gender-field {
          width: 100%;
          height: 50px;
          border-radius: 25px;
          border: solid 2px #000000;

          padding: 2px 10px;
        }
      }
    }
  }
}

.next-btn {
  position: fixed;
  bottom: 0;
  z-index: 6;

  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 500px;
  width: 100%;
  height: 70px;
  border-radius: 20px 20px 0px 0px;
  background-color: #000000;

  .next-btn-text {
    color: white;
    font-size: 24px;
    font-weight: 600;

    margin: auto;
  }
}
