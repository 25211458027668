body {
  letter-spacing: -0.5px;
}

.upper {
  position: relative;
  z-index: 0;

  height: 70%;
  background-color: #ff9a22;

  text-align: center;

  padding-top: 40px;

  .top-title {
    position: relative;
    color: #333333;
    font-size: 18px;
    font-weight: 600;

    margin-bottom: 14px;
  }

  .type-text {
    font-size: 45px;
    font-weight: 600;

    margin-bottom: 25px;
  }

  .type-img {
    width: 150px;
    height: 150px;

    margin: auto;
    margin-bottom: 25px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .detail-type {
    width: 70%;

    margin: auto;

    .dt1 {
      font-size: 16px;
    }

    .dt2 {
      font-size: 22px;
    }
  }

  .yellow-img {
  }
}

.bottom {
  position: relative;
  z-index: 5;

  background-color: green;
  width: 100%;
  height: calc(30% + 500px);
  border-radius: 30px 30px 0 0;
  top: -30px;

  background-color: white;

  padding-left: 10%;
  padding-right: 10%;
  padding-top: 12%;

  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  .bottom-content {
    width: 100%;

    .btm-top-title {
      font-size: 20px;
      font-weight: 600;

      margin-bottom: 18px;
    }

    .btm-type-text {
      opacity: 0.7;
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;

      text-align: justify;
      text-justify: inter-word;

      margin-bottom: 40px;
    }
  }
}

.bottom-btns {
  position: fixed;
  width: 100%;
  height: 100px;
  background-color: black;
  bottom: 0;
  color: white;
}

@media only screen and (max-device-height: 660px) {
  .upper {
    height: 70%;
    padding-top: 30px;

    .top-title {
      position: relative;
      color: 333333;
      font-size: 18px;
      font-weight: 600;

      margin-bottom: 9px;
    }

    .type-text {
      font-size: 40px;
      font-weight: 600;

      margin-bottom: 24px;
    }

    .type-img {
      width: 150px;
      height: 150px;

      margin: auto;
      margin-bottom: 16px;
    }

    .detail-type {
      width: 90%;

      margin: auto;

      .dt1 {
        font-size: 14px;
      }

      .dt2 {
        font-size: 20px;
      }
    }
  }

  .bottom {
    // height: calc(30% + 30px);
    top: -30px;
  }
}
