.title_bar_wrapper {
  position: relative;
  height: 52px;

  background-color: white;
  margin-bottom: 17px;
}

.title_bar {
  position: fixed;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 52px;

  text-align: center;

  box-sizing: border-box;
  padding: 10px 0px;

  background-color: white;

  border-bottom: 1px solid rgba(0, 0, 0, 0.03);

  margin-bottom: 17px;
}

.title_bar span {
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.5px;
  color: #000000;
}

.criteria_list {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;

  flex-wrap: wrap;

  box-sizing: border-box;
  padding: 5px 22px;

  margin-bottom: 17px;
}

.criteria_list .criteria_selected {
  width: fit-content;
  height: fit-content;

  border-radius: 14px;
  background-color: #fcb03f;

  box-sizing: border-box;
  padding: 4px 14px;

  margin-right: 10px;
}

.criteria_list .criteria_selected span {
  height: 18px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.6px;
  text-align: center;
  color: #ffffff;
}

.criteria_list .criteria {
  width: fit-content;
  height: fit-content;

  border-radius: 14px;
  background-color: #f6f6f6;

  box-sizing: border-box;
  padding: 4px 14px;

  margin-right: 10px;
}

.criteria_list .criteria span {
  height: 18px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.6px;
  text-align: center;
  color: #b1b1b1;
}

/* */

.ranking_list {
  box-sizing: border-box;
  padding: 0 22px;
}

.ranking_list .ranking_item {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;

  height: 44px;

  margin-bottom: 25px;
}

.ranking_list .ranking_item .ranking_num {
  width: 9px;
  height: 20px;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.3px;
  text-align: left;
  color: #414141;

  align-self: flex-start;

  margin-right: 8px;
}

.ranking_list .ranking_item .product_img {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  width: 44px;
  height: 44px;

  margin-right: 9px;
}

.ranking_list .ranking_item .product_img img {
  max-width: 100%;
  max-height: 100%;
}

.ranking_list .ranking_item .product_info {
  flex: 1 1 0;
}

.ranking_list .ranking_item .product_info .brand {
  height: 15px;

  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;

  margin-bottom: 1px;
}

.ranking_list .ranking_item .product_info .brand .manufacturer {
  width: fit-content;
  height: 15px;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: left;
  color: #ffffff;

  background-color: #a5a5a5;

  padding: 0 3px;

  margin-right: 2px;
}

.ranking_list .ranking_item .product_info .brand .brand_line {
  height: 15px;

  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: left;
  color: #a5a5a5;
}

.ranking_list .ranking_item .product_info .product_name {
  height: 22px;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: -0.75px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
}

.ranking_list .ranking_item .ranking_trophy {
  margin-right: 3px;
}

.ranking_list .ranking_item .product_rating {
  justify-self: flex-end;

  width: fit-content;
  height: 30px;

  text-align: center;
}

.ranking_list .ranking_item .product_rating img {
  width: 12px;
  height: 12px;
}

.ranking_list .ranking_item .product_rating .rating_text {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.24px;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
}
