@import "common";

.modal-bg-wrapper {
}

.modal-bg {
  width: 100%;
  height: 100%;

  position: absolute;

  background-color: rgba(0, 0, 0, 0.67);

  @include center_align_with_flex;
}

.modal-login {
  position: fixed;

  width: 279px;
  height: 418px;
  border-radius: 18px;
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;

  .close_btn {
    position: absolute;

    width: 15px;
    height: 15px;

    top: 22px;
    right: 22px;

    img {
      position: absolute;
      top: 0;
      left: 0;

      max-width: 100%;
      max-height: 100%;

      object-fit: contain;
    }
  }

  .splash_image {
    position: relative;

    width: 100%;
    height: 220px;

    text-align: center;

    margin-top: 50px;
    margin-bottom: 26px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .message {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: -0.75px;
    text-align: center;
    color: #414141;

    margin-bottom: 30px;
  }

  .login_btn {
    @include center_align_with_flex;

    width: 231px;
    height: 50px;
    border-radius: 10px;
    background-color: #fcb03f;

    & span {
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.47;
      letter-spacing: -0.75px;
      text-align: left;
      color: #ffffff;
    }
  }
}
