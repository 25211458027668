.placeholder {
  position: relative;
  height: 53px;
  background-color: white;
}

.top_search {
  position: fixed;

  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;

  max-width: 500px;
  width: 100%;
  height: 53px;
  top: env(safe-area-inset-top);

  background-color: white;

  box-sizing: border-box;
  padding: 10px 22px;
  padding-left: 0px;

  z-index: 100;
}

.top_search .back_btn {
  display: flex;
  padding: 13px 11px 13px 20px;
}

.top_search .back_btn img {
  width: 9px;
  height: 15px;
  vertical-align: top;
}

.search_bar {
  flex: 1 1 0;

  position: relative;

  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;

  height: 33px;
  border-radius: 7px;
  background-color: #f7f7f7;

  margin-left: 3px;
  margin-right: 11px;
}

.search_bar .text_field {
  width: calc(100% - 60px);
  height: 20px;

  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -1px;
  text-align: left;
  color: #5a5a5a;
  background-color: transparent;
  border: 0;

  margin-left: 10px;
}

.search_bar .text_field::placeholder {
  font-weight: 400;
  color: #cccccc;
}

.search_bar .clear_btn {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 4.5px;

  padding: 10px;
}

.search_bar .clear_btn img {
  width: 7px;
  height: 7px;
}

.top_search .barcode_btn {
}
.top_search .barcode_btn img {
  width: 33px;
  height: 33px;
}
