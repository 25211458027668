.video-bg {
  position: fixed;
  z-index: 0;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  width: 100%;
  height: 100%;

  overflow: hidden;

  background-color: transparent;

  video {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 105%;
    height: 105%;
    object-fit: cover;
  }

  .black-opacity {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: rgba(50, 50, 50, 0.4);
  }
}

.logo {
  position: absolute;
  z-index: 10;

  width: 100%;

  margin-top: 35%;

  img {
    margin: auto;
  }
}

.btns {
  position: absolute;
  bottom: 0;
  z-index: 1;

  width: 100%;

  div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: auto;
    margin-bottom: 11px;
  }

  .info_text {
    height: 44px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -1.5px;
    text-align: center;
    color: #fff;

    margin-bottom: 57px;
  }

  .kakao {
    width: 277px;
    height: 53px;
    background-color: #ffe812;

    .text {
      height: 22px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: -1.28px;
      text-align: center;
      color: #3c1e1e;
    }
  }

  .naver {
    width: 277px;
    height: 53px;
    background-color: #00c73c;

    .text {
      height: 22px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: -1.28px;
      text-align: center;
      color: #ffffff;
    }
  }

  .facebook {
    width: 277px;
    height: 53px;
    background-color: #3c5a99;

    .text {
      height: 22px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: -1.28px;
      text-align: center;
      color: #ffffff;
    }
  }

  .without_login {
    width: 277px;
    height: 53px;

    margin-bottom: 38px;

    .text {
      height: 19px;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: -1.28px;
      text-align: center;
      color: #ffffff;
      // color: #3c1e1e;
    }
  }
}
