/* 하단 내비게이션 */
.app_nav_device_margin {
  background-color: white;
  height: calc(env(safe-area-inset-bottom) * 0.6);
}

.app_nav {
  position: relative;
  flex: initial;

  width: 100%;
  max-width: 500px;

  left: 0;
  bottom: 0;
}

.app_nav_wrapper {
  position: relative;

  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}

.app_nav .navbar {
  display: flex;
  width: 100%;
  height: 66px;
  background-color: white;

  border-top: solid 0.5px #e2e2e2;
  box-sizing: border-box;

  z-index: 1000;
}

.app_nav .pot {
  position: absolute;

  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  background-color: #fcb03f;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  box-sizing: border-box;
  bottom: 33px;

  z-index: 1002;
}

.app_nav .pot .pot_icon {
  width: 25px;
  height: 25px;
}

.app_nav .pot_border {
  display: inline-block;
  position: absolute;

  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  width: 65px;
  height: 65px;

  background-color: white;
  border-radius: 50%;
  border: solid 0.5px #e2e2e2;
  box-sizing: border-box;
  bottom: 24px;
  z-index: 1;
}

.app_nav .pot_border_fill {
  display: inline-block;
  position: absolute;

  width: 63px;
  height: 63px;

  background-color: white;
  border-radius: 50%;
  box-sizing: border-box;
  bottom: 25px;
  z-index: 1001;
}

.app_nav .navbar_item {
  flex: 1 1 0px;

  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 0;
}

.app_nav .navbar_item_center_space {
  width: 63px;
  background-color: white;
}

.app_nav .navbar_item .home {
  width: 20px;

  margin-bottom: 4px;
}

.app_nav .navbar_item .search {
  width: 20px;

  margin-bottom: 3px;
}

.app_nav .navbar_item .ranking {
  width: 17px;

  margin-bottom: 3px;
}

.app_nav .navbar_item .social {
  width: 17.5px;

  margin-bottom: 3px;
}

.app_nav .navbar_item .item_txt {
  height: 13px;
  font-size: 9px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: -0.18px;
  color: #838383;
}

.app_nav .txt_selected {
  color: #fcb03f !important;
}
