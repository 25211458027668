.top_margin {
  height: 15px;
}

.top_nav {
  position: relative;

  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  height: 21px;

  box-sizing: border-box;
  margin: 0 22px;
  margin-bottom: 14px;
}

.top_nav .back_btn {
  position: absolute;

  left: 0;
}

.top_nav .back_btn img {
  width: 9.3px;
  vertical-align: text-top;
}

.top_nav .like_btn {
  position: absolute;

  right: 0;
}

.top_nav .like_btn img {
  width: 22.8px;
  height: 21px;
}

/* */

.product_info {
  box-sizing: border-box;
  padding: 0 22px;

  margin-bottom: 11px;
}

.product_image {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;

  width: 90%;
  height: 230px;

  margin: auto;
  margin-bottom: 12px;
}

.product_image img {
  max-width: 100%;
  max-height: 100%;
}

/* 상품 정보 텍스트 */

.product_name {
}

.product_name .brand {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;

  height: 20px;

  margin-bottom: 2px;
}

.product_name .brand .manufacturer {
  height: 20px;
  background-color: #a5a5a5;

  box-sizing: border-box;
  padding: 1px 4px;

  font-size: 12px;
  font-weight: 500;
  line-height: 1.35;
  letter-spacing: -0.24px;
  text-align: center;
  color: #ffffff;

  margin-right: 4px;
}

.product_name .brand .brand_line {
  height: 17px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: -0.5px;
  text-align: center;
  color: #a5a5a5;
}

.product_name .name {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 27px;
  letter-spacing: -0.9px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);

  margin-bottom: 7px;
}

.product_rating {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;

  margin-bottom: 6px;
}
.product_rating .stars {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;

  margin-right: 4px;
}

.product_rating .stars img {
  width: 11.6px;
  height: 11.6px;
}

.product_rating .rating_num {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);

  margin-right: 3px;
}

.product_rating .rated_count {
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.2px;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
}

.product_rating .rated_count::before {
  content: "(";
}

.product_rating .rated_count::after {
  content: ")";
}

.product_price {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: flex-end;
}

.product_price .price_text {
  height: 23px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: -0.34px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);

  margin-right: 1px;
}

.product_price .price_text_won {
  height: 23px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.7;
  letter-spacing: -0.28px;
  color: rgba(0, 0, 0, 0.8);

  margin-right: 7px;
}

.discount_tags {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;

  margin-top: 5px;
}

.discount_tags .discount_tag {
  text-align: center;
  height: 22px;
  background-color: #f26838;

  box-sizing: border-box;
  padding: 4px 6px;

  margin-right: 5px;
}

.discount_tags .discount_tag span {
  height: 18px;
  font-size: 10px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.18px;
  text-align: center;
  color: #ffffff;
  vertical-align: super;
}

/* *** */

.sep_line {
  height: 1px;

  background-color: rgba(0, 0, 0, 0.04);
}

/* *** */

.product_tags_and_share {
  box-sizing: border-box;
  padding: 12px 22px;

  margin-bottom: 10px;
}

.product_tags {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;

  flex-wrap: wrap;
}

.product_tag {
  width: fit-content;
  height: 23px;
  background-color: #fcb03f;

  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.4px;
  text-align: center;
  color: #ffffff;

  box-sizing: border-box;
  padding: 4px 6px;

  margin-right: 4px;
  margin-bottom: 8px;
}

/* **** */

.section_tabs {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;

  height: 43px;

  box-sizing: content-box;
  padding: 0 0px;
}

.section_tab_wrapper {
  flex: 1;
  width: fit-content;
}

.section_tab {
  height: 18px;
  padding: 10px 14px;

  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
}

.section_tab_selected {
  color: #fcb03f;
}

.section_tab_bar {
  height: 3px;
}

.section_tab_bar_selected {
  background-color: #fcb03f;
}

.sep_line_thick {
  height: 3px;
  opacity: 0.04;
  background-color: #868686;
}
